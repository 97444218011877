import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { DateTime, Duration } from 'luxon';
import {
  MeetingParticipantWithContactInfo,
  MeetingParticipantWithTurns,
  MeetingSignalWithParticipantInfo,
  MeetingTopicFlowItemWithTopicStarterInfo,
  MeetingTranscriptWithParticipantInfo,
  MeetingTurnFlowItemWithParticipantInfo,
} from '../../types/meetingDetails.types';
import { groupBy } from '../../utils/groupBy';
import { RootState } from '../store';

export const meetingDateStringSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.createdAt,
  (createdAt) => {
    if (!createdAt) {
      return '';
    }

    return DateTime.fromISO(createdAt).toFormat('MMM dd, yyyy, hh:mm a');
  }
);

export const meetingShortDateStringSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.createdAt,
  (createdAt) => {
    if (!createdAt) {
      return '';
    }

    return DateTime.fromISO(createdAt).toFormat('MMM dd, yyyy');
  }
);

export const meetingDateRangeStringSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.createdAt,
  (state: RootState) => state.meetingDetails.data?.duration,
  (createdAt, duration) => {
    if (!createdAt || !duration) {
      return '';
    }

    return `${DateTime.fromISO(createdAt).toFormat(
      'hh:mm a'
    )} - ${DateTime.fromISO(createdAt)
      .plus({ seconds: duration })
      .toFormat('hh:mm a')}`;
  }
);

export const meetingRecordingDurationStringSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.duration,
  (duration) => {
    if (!duration) {
      return '';
    }

    const { minutes, seconds } = Duration.fromObject({
      seconds: duration || 0,
    }).shiftTo('minutes', 'seconds');

    if (!minutes) {
      return `${Math.round(seconds)} sec`;
    }

    return `${minutes + (seconds >= 30 ? 1 : 0)} min${
      minutes === 1 ? '' : 's'
    }`;
  }
);

export const meetingHostWithContactInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.host,
  (state: RootState) => state.meetingDetails.data?.contacts,
  (host, contacts = []) => {
    if (!host) {
      return null;
    }

    const contact =
      contacts.find((item) => item.contactId === host.contactId) || null;

    const hostWithContactInfo: MeetingParticipantWithContactInfo = {
      ...host,
      contact,
    };

    return hostWithContactInfo;
  }
);

export const meetingParticipantsWithContactInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.participants,
  (state: RootState) => state.meetingDetails.data?.contacts,
  (participants = [], contacts = []) => {
    if (!participants.length) {
      return [];
    }

    return participants
      .map((participant) => {
        const contact =
          contacts.find((item) => item.contactId === participant.contactId) ||
          null;

        const participantWithContactInfo: MeetingParticipantWithContactInfo = {
          ...participant,
          contact,
        };

        return participantWithContactInfo;
      })
      .sort((a, b) => {
        const firstStatsValue = a.stats?.talkTime?.percentage || null;
        const secondStatsValue = b.stats?.talkTime?.percentage || null;

        if (firstStatsValue === null) {
          return 1;
        }

        if (secondStatsValue === null) {
          return -1;
        }

        return secondStatsValue - firstStatsValue;
      });
  }
);

export const meetingParticipantsBySideSelector = createDraftSafeSelector(
  meetingParticipantsWithContactInfoSelector,
  (participants) => groupBy(participants, (participant) => participant.side)
);

export const transcriptsWithParticipantInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.transcripts,
  meetingParticipantsWithContactInfoSelector,
  (transcripts = [], participants = []) => {
    if (!transcripts.length) {
      return [];
    }

    const participantsByIdMap = participants.reduce<
      Record<string, MeetingParticipantWithContactInfo>
    >((acc, participant) => {
      acc[participant.participantId] = participant;
      return acc;
    }, {});

    return transcripts.map((transcript) => {
      const participant = participantsByIdMap[transcript.participantId] || null;

      const transcriptWithParticipantInfo: MeetingTranscriptWithParticipantInfo = {
        ...transcript,
        participant,
      };

      return transcriptWithParticipantInfo;
    });
  }
);

export const turnsWithParticipantInfoSelector = createDraftSafeSelector(
  (state: RootState) =>
    state.meetingDetails.data?.analysis?.conversationFlow?.turnsFlow,
  meetingParticipantsWithContactInfoSelector,
  (turns = [], participants = []) => {
    if (!turns.length) {
      return [];
    }

    const participantsByIdMap = participants.reduce<
      Record<string, MeetingParticipantWithContactInfo>
    >((acc, participant) => {
      acc[participant.participantId] = participant;
      return acc;
    }, {});

    return turns.map((turn) => {
      const participant = participantsByIdMap[turn.participantId] || null;

      const turnWithParticipantInfo: MeetingTurnFlowItemWithParticipantInfo = {
        ...turn,
        participant,
      };

      return turnWithParticipantInfo;
    });
  }
);

export const participantsWithTurnsSelector = createDraftSafeSelector(
  (state: RootState) =>
    state.meetingDetails.data?.analysis?.conversationFlow?.turnsFlow,
  meetingParticipantsWithContactInfoSelector,
  (turns = [], participants = []) => {
    if (!participants?.length) {
      return [];
    }

    const participantsWithTurns: MeetingParticipantWithTurns[] = participants.map(
      (el) => ({
        participant: el,
        turns: turns.filter((turn) => turn.participantId === el.participantId),
      })
    );

    return participantsWithTurns;
  }
);

export const topicsWithTopicStarterInfoSelector = createDraftSafeSelector(
  (state: RootState) =>
    state.meetingDetails.data?.analysis?.conversationFlow?.topicsFlow,
  meetingParticipantsWithContactInfoSelector,
  (topics = [], participants = []) => {
    if (!topics.length) {
      return [];
    }

    const participantsByIdMap = participants.reduce<
      Record<string, MeetingParticipantWithContactInfo>
    >((acc, participant) => {
      acc[participant.participantId] = participant;
      return acc;
    }, {});

    return topics.map((topic) => {
      const topicStarter = participantsByIdMap[topic.topicStarterId] || null;

      const topicWithParticipantInfo: MeetingTopicFlowItemWithTopicStarterInfo = {
        ...topic,
        topicStarter,
      };

      return topicWithParticipantInfo;
    });
  }
);

export const signalsWithParticipantInfoSelector = createDraftSafeSelector(
  (state: RootState) =>
    state.meetingDetails.data?.analysis?.conversationFlow?.signals,
  meetingParticipantsWithContactInfoSelector,
  (signals = [], participants = []) => {
    if (!signals.length) {
      return [];
    }

    const participantsByIdMap = participants.reduce<
      Record<string, MeetingParticipantWithContactInfo>
    >((acc, participant) => {
      acc[participant.participantId] = participant;
      return acc;
    }, {});

    return signals.map((signal) => {
      const participant = participantsByIdMap[signal.participantId] || null;

      const signalWithParticipantInfo: MeetingSignalWithParticipantInfo = {
        ...signal,
        participant,
      };

      return signalWithParticipantInfo;
    });
  }
);

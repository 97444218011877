export const groupBy = <T, K extends keyof any>(
  arr: T[],
  getKey: (item: T) => K
): Record<K, T[]> => {
  return arr.reduce((acc, item) => {
    const group = getKey(item);

    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].push(item);

    return acc;
  }, {} as Record<K, T[]>);
};

import React from 'react';

export const DotsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M7 3.66699C7 3.11471 7.44772 2.66699 8 2.66699C8.55228 2.66699 9 3.11471 9 3.66699C9 4.21928 8.55228 4.66699 8 4.66699C7.44772 4.66699 7 4.21928 7 3.66699Z"
      fill="#646F87"
    />
    <path
      d="M7 8.00033C7 7.44804 7.44772 7.00033 8 7.00033C8.55228 7.00033 9 7.44804 9 8.00033C9 8.55261 8.55228 9.00033 8 9.00033C7.44772 9.00033 7 8.55261 7 8.00033Z"
      fill="#646F87"
    />
    <path
      d="M8 11.3337C7.44772 11.3337 7 11.7814 7 12.3337C7 12.8859 7.44772 13.3337 8 13.3337C8.55228 13.3337 9 12.8859 9 12.3337C9 11.7814 8.55228 11.3337 8 11.3337Z"
      fill="#646F87"
    />
  </svg>
);

import React from 'react';

export const ChevronRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08803 6.65848C8.41792 6.28145 8.41792 5.71849 8.08802 5.34147L4.87612 1.67074C4.69428 1.46292 4.3784 1.44187 4.17058 1.62371C3.96276 1.80555 3.9417 2.12143 4.12355 2.32925L7.33545 5.99997L4.12354 9.67074C3.9417 9.87856 3.96276 10.1944 4.17058 10.3763C4.3784 10.5581 4.69428 10.5371 4.87612 10.3292L8.08803 6.65848Z"
      fill="#070B12"
    />
  </svg>
);

import { ThreadDetailsState } from '../../../store/threadDetails/threadDetails.types';
import { User } from '../../../types';
import { MeetingDetails } from '../../../types/meetingDetails.types';
import {
  RelationshipEventNavigation,
  RelationshipEventType,
} from '../../../types/relationshipEvent.types';

const getEvent = ({
  meetingDetails,
  threadDetails,
  isMeetingDetailsPage,
  isThreadDetailsPage,
}: {
  meetingDetails: MeetingDetails | null;
  threadDetails: ThreadDetailsState;
  isMeetingDetailsPage: boolean;
  isThreadDetailsPage: boolean;
}) => {
  if (isMeetingDetailsPage) {
    return meetingDetails;
  }

  if (isThreadDetailsPage) {
    return threadDetails;
  }

  return null;
};

const getEventTypePath = ({ type }: RelationshipEventNavigation) => {
  if (type === RelationshipEventType.ONLINE_MEETING) {
    return 'meetings';
  }

  return 'threads';
};

export const getEventNavigationUrls = ({
  user,
  meetingDetails,
  threadDetails,
  isMeetingDetailsPage,
  isThreadDetailsPage,
}: {
  user: User | null;
  meetingDetails: MeetingDetails | null;
  threadDetails: ThreadDetailsState;
  isMeetingDetailsPage: boolean;
  isThreadDetailsPage: boolean;
}): { prevEventUrl: string | null; nextEventUrl: string | null } => {
  if (!user) {
    return {
      prevEventUrl: null,
      nextEventUrl: null,
    };
  }

  const event = getEvent({
    meetingDetails,
    threadDetails,
    isMeetingDetailsPage,
    isThreadDetailsPage,
  });

  if (event && event.account) {
    const { account, prevTouchPoint, nextTouchPoint } = event;

    return {
      prevEventUrl: prevTouchPoint
        ? `/orgs/${user.orgId}/users/${user.userId}/relationships/${
            account.accountId
          }/${getEventTypePath(prevTouchPoint)}/${prevTouchPoint.touchPointId}`
        : null,
      nextEventUrl: nextTouchPoint
        ? `/orgs/${user.orgId}/users/${user.userId}/relationships/${
            account.accountId
          }/${getEventTypePath(nextTouchPoint)}/${nextTouchPoint.touchPointId}`
        : null,
    };
  }

  return {
    prevEventUrl: null,
    nextEventUrl: null,
  };
};

import React from 'react';

export const ThreeDotAttentionIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_14179_160647)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75205 1.43648C2.97111 0.967045 3.44226 0.666992 3.96029 0.666992H4.03977C4.5578 0.666992 5.02894 0.967046 5.24801 1.43648L7.33602 5.91078C7.64541 6.57376 7.16145 7.33366 6.42983 7.33366H1.57022C0.838605 7.33366 0.35465 6.57375 0.664041 5.91077L2.75205 1.43648ZM4.00003 2.00033C4.18412 2.00033 4.33336 2.14956 4.33336 2.33366V4.66699C4.33336 4.85109 4.18412 5.00033 4.00003 5.00033C3.81593 5.00033 3.66669 4.85109 3.66669 4.66699V2.33366C3.66669 2.14956 3.81593 2.00033 4.00003 2.00033ZM4.00003 6.16699C4.23015 6.16699 4.41669 5.98044 4.41669 5.75033C4.41669 5.52021 4.23015 5.33366 4.00003 5.33366C3.76991 5.33366 3.58336 5.52021 3.58336 5.75033C3.58336 5.98044 3.76991 6.16699 4.00003 6.16699Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_14179_160647">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

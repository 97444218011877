import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Skeleton } from 'antd';
import {
  EventFrameHeaderTitle,
  EventHeaderTitleProps,
} from './components/EventHeaderTitle/EventFrameHeaderTitle';
import { Account } from '../../../../types/account.types';
import { LogoPlaceholderIcon } from '../../../Icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { openAssignAccountModal } from '../../../../store/relationshipFlows/relationshipFlows.slice';
import {
  Avatar,
  AvatarProps,
  AvatarSize,
  Button,
  ButtonSize,
  ButtonVariant,
  Tooltip,
  TooltipProps,
} from '../../../SoundWave';
import { getEventNavigationUrls } from '../../../EventPagination/utils/getEventNavigationUrls';
import { PATHS } from '../../../../constants/paths';
import { ChevronLeftIcon } from '../../../EventPagination/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '../../../EventPagination/icons/ChevronRightIcon';
import { ClockIcon } from './icons/ClockIcon';
import { CalendarIcon } from './icons/CalendarIcon';
import {
  meetingDateRangeStringSelector,
  meetingHostWithContactInfoSelector,
  meetingParticipantsWithContactInfoSelector,
  meetingRecordingDurationStringSelector,
  meetingShortDateStringSelector,
} from '../../../../store/meetingDetails/meetingDetails.selectors';
import { ThreeDotIcon } from './icons/ThreeDotIcon';
import { getTotalDurationString } from '../../../../pages/ThreadDetails/components/ThreadOverview/ThreadOverview.utils';
import { toggleManageParticipantsModal } from '../../../../store/meetingDetails/meetingDetails.slice';
import ManageParticipantsModal from './components/ManageParticipantsModal/ManageParticipantsModal';
import { ThreeDotAttentionIcon } from './icons/ThreeDotAttentionIcon';

export enum DescriptionAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface EventFrameHeaderProps {
  titleProps: EventHeaderTitleProps;
  isLoading: boolean;
  leftIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  rightIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  account?: Account | null;
  showAccount?: boolean;
  eventId?: string;
  buttonProps?: {
    text: string;
    disabled?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    tooltipProps?: TooltipProps;
    onClick: () => void;
  };
  isEmailAnalysis?: boolean;
}

export const EventFrameHeader: React.FC<EventFrameHeaderProps> = ({
  titleProps,
  isLoading,
  leftIcon,
  rightIcon,
  buttonProps,
  account,
  eventId,
  showAccount = false,
  isEmailAnalysis = false,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const meetingDetails = useAppSelector((state) => state.meetingDetails.data);
  const threadDetails = useAppSelector((state) => state.threadDetails);

  const meetingDetailsMatch = useRouteMatch(PATHS.MEETING_DETAILS);
  const meetingDetailsTestMatch = useRouteMatch(PATHS.MEETING_DETAILS_TEST);
  const threadDetailsMatch = useRouteMatch(PATHS.THREAD_DETAILS);
  const touchPointDetailsMatch = useRouteMatch(PATHS.TOUCH_POINT_DETAILS);

  const meetingDate = useAppSelector(meetingShortDateStringSelector);
  const meetingDateRange = useAppSelector(meetingDateRangeStringSelector);
  const meetingRecordingDurationString = useAppSelector(
    meetingRecordingDurationStringSelector
  );
  const hostWithContactInfo = useAppSelector(
    meetingHostWithContactInfoSelector
  );
  const participantsWithContactInfo = useAppSelector(
    meetingParticipantsWithContactInfoSelector
  );

  const emailParticipants = useAppSelector(
    (state) => state.threadDetails.contacts
  );

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const { prevEventUrl, nextEventUrl } = getEventNavigationUrls({
    user,
    meetingDetails,
    threadDetails,
    isMeetingDetailsPage: !!meetingDetailsMatch || !!meetingDetailsTestMatch,
    isThreadDetailsPage: !!threadDetailsMatch || !!touchPointDetailsMatch,
  });

  const LeftIconComponent = leftIcon || null;
  const RightIconComponent = rightIcon || null;
  const ButtonIconComponent = buttonProps?.icon || null;

  const participantsAvatarProps = useMemo(
    () =>
      participantsWithContactInfo.map((participant) => ({
        src: participant.contact?.metadata?.avatarSrc || '',
        name: participant.contact?.metadata?.name || participant.alias || '',
        side: participant.side,
        className: participant.contact?.metadata?.avatarSrc
          ? ''
          : 'meeting-analysis__avatar',
      })),
    [participantsWithContactInfo]
  );

  const emailParticipantsAvatarProps = useMemo(
    () =>
      emailParticipants.map((participant) => ({
        src: participant?.metadata?.avatarSrc || '',
        name: participant?.metadata?.name || '',
        // side: participant?.metadata.name,
        className: participant?.metadata?.avatarSrc
          ? ''
          : 'meeting-analysis__avatar',
      })),
    [emailParticipants]
  );

  const threadStartedBy =
    threadDetails?.selectedBranch?.emails &&
    threadDetails?.selectedBranch?.emails[0].sender?.metadata;

  const threadStartedAt =
    threadDetails?.selectedBranch?.emails?.[0]?.sentAt &&
    DateTime.fromISO(
      threadDetails?.selectedBranch?.emails?.[0]?.sentAt
    ).toFormat('MMM dd, yyyy');

  const lastThreadEmailAt =
    threadDetails?.selectedBranch?.emails?.[
      threadDetails?.selectedBranch?.emails?.length - 1
    ]?.sentAt &&
    DateTime.fromISO(
      threadDetails?.selectedBranch?.emails?.[
        threadDetails?.selectedBranch?.emails?.length - 1
      ]?.sentAt
    ).toFormat('MMM dd, yyyy');

  const threadLifeTime =
    lastThreadEmailAt && threadStartedAt
      ? `${threadStartedAt} - ${lastThreadEmailAt}`
      : 'N/A';

  const durationInWeeks = threadDetails?.selectedBranch?.branchDurationWeeks
    ? getTotalDurationString(
        typeof threadDetails?.selectedBranch?.branchDurationWeeks === 'number'
          ? threadDetails?.selectedBranch?.branchDurationWeeks
          : null
      )
    : 'N/A';

  const attrs = {
    container: {
      className: `event-frame-header${
        showDisclaimer ? ' event-frame-header--with-disclaimer' : ''
      }`,
    },
    navigation: {
      previousButton: {
        size: ButtonSize.XS,
        variant: ButtonVariant.Secondary,
        className: 'event-pagination__button',
        disabled: isLoading || !prevEventUrl,
        onClick: () => {
          if (prevEventUrl) {
            history.push(prevEventUrl);
          }
        },
      },
      nextButton: {
        size: ButtonSize.XS,
        variant: ButtonVariant.Secondary,
        className: 'event-pagination__button',
        disabled: isLoading || !nextEventUrl,
        onClick: () => {
          if (nextEventUrl) {
            history.push(nextEventUrl);
          }
        },
      },
    },
    left: {
      wrapper: {
        className: 'event-frame-header__left',
      },
      accountButton: {
        disabled: isLoading || !!account,
        type: 'button' as const,
        className: 'event-frame-header__account-button',
        onClick: () => {
          if (!account && eventId) {
            setShowDisclaimer(false);
            dispatch(openAssignAccountModal({ eventId }));
          }
        },
      },
      accountLogo: {
        size: AvatarSize.XXL,
        src: account?.avatarSrc || '',
        name: account?.name || '',
        placeholder: LogoPlaceholderIcon,
        hasBorder: !account,
        shape: 'square' as const,
        className: 'event-frame-header__account-logo',
      },
      eventDetails: {
        wrapper: {
          className: 'event-frame-header__left__event-details',
        },

        top: {
          wrapper: {
            className: 'event-frame-header__left__event-details__top',
          },
          account: {
            className: 'event-frame-header__left__event-details__top-account',
          },
          titleSkeleton: {
            active: true,
            paragraph: false,
            loading: isLoading,
            style: {
              width: 'auto',
            },
            title: {
              style: {
                width: 200,
                height: 36,
                margin: 0,
                borderRadius: 4,
              },
            },
          },
          separator: {
            className: 'event-frame-header__left__event-details__top-separator',
          },
          eventName: {
            className:
              'event-frame-header__left__event-details__top-event-name',
          },
        },
        bottom: {
          wrapper: {
            className: 'event-frame-header__left__event-details__bottom',
          },
          date: {
            className: 'event-frame-header__left__event-details__bottom-date',
          },
          time: {
            className: 'event-frame-header__left__event-details__bottom-time',
          },
          duration: {
            className:
              'event-frame-header__left__event-details__bottom-duration',
          },
          value: {
            className: 'event-frame-header__left__event-details__bottom-value',
          },
          separator: {
            className:
              'event-frame-header__left__event-details__bottom-separator',
          },
        },
      },
    },
    right: {
      wrapper: {
        className: 'event-frame-header__right',
      },
      content: {
        wrapper: {
          className: 'event-frame-header__right__content',
        },

        peopleInfoWrapper: {
          className: 'event-frame-header__right__content__people',
        },

        host: {
          wrapper: {
            className: 'event-frame-header__right__content__host',
          },
          avatar: {
            className: 'event-frame-header__right__content__host-avatar',
            size: AvatarSize.S,
            src: isEmailAnalysis
              ? threadStartedBy?.avatarSrc || ''
              : hostWithContactInfo?.contact?.metadata?.avatarSrc || '',
            name: isEmailAnalysis
              ? threadStartedBy?.name || ''
              : hostWithContactInfo?.contact?.metadata?.name ||
                hostWithContactInfo?.alias ||
                '',
            side: hostWithContactInfo?.side,
          },
          name: {
            className: 'event-frame-header__right__content__host-name',
          },
          text: {
            className: 'event-frame-header__right__content__host-text',
          },
        },

        praticipants: {
          wrapper: {
            className: 'event-frame-header__right__content__participants',
          },
          avatars: {
            wrapper: {
              className:
                'event-frame-header__right__content__participants__avatars-group',
            },
            avatar: {
              size: AvatarSize.S,
            },
          },
          avatarsGroupCounter: {
            className:
              'event-frame-header__right__content__participants__avatars-group-counter',
          },
          avatarsGroupCounterText: {
            className:
              'event-frame-header__right__content__participants__avatars-group-counter-text',
          },
        },

        threeDotMenu: {
          wrapper: {
            className: 'event-frame-header__right__content__three-dot-menu',
            onClick: () => {
              dispatch(toggleManageParticipantsModal(true));
            },
          },
          attentionIcon: {
            className:
              'event-frame-header__right__content__three-dot-menu-icon',
          },
          button: {
            wrapper: {
              className:
                'event-frame-header__right__content__three-dot-menu__button',
            },
            icon: {
              className:
                'event-frame-header__right__content__three-dot-menu__button-icon',
            },
          },
        },
      },
    },
    leftIcon: {
      className: 'event-frame-header__left-icon',
    },
    rightIcon: {
      className: 'event-frame-header__right-icon',
    },
  };

  const renderAvatars = (avatars: AvatarProps[]) => {
    if (!avatars.length) {
      return null;
    }

    const avatarsToRender = avatars.length > 3 ? avatars.slice(0, 3) : avatars;
    const diff = avatars.length - avatarsToRender.length;

    return (
      <>
        {avatarsToRender.map((avatar, index) => (
          <Avatar
            {...attrs.right.content.praticipants.avatars.avatar}
            {...avatar}
            key={index}
          />
        ))}
        {diff ? (
          <span {...attrs.right.content.praticipants.avatarsGroupCounter}>
            +{diff}{' '}
            <span {...attrs.right.content.praticipants.avatarsGroupCounterText}>
              participant{diff > 1 ? 's' : ''}
            </span>
          </span>
        ) : null}
      </>
    );
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.left.wrapper}>
        <Button {...attrs.navigation.previousButton}>
          <ChevronLeftIcon />
        </Button>
        <button {...attrs.left.accountButton}>
          <Avatar {...attrs.left.accountLogo} />
        </button>
        <div {...attrs.left.eventDetails.wrapper}>
          <div {...attrs.left.eventDetails.top.wrapper}>
            <span>{account?.name}</span>
            <div {...attrs.left.eventDetails.top.separator}>|</div>
            <EventFrameHeaderTitle {...titleProps} />
          </div>
          <div {...attrs.left.eventDetails.bottom.wrapper}>
            <div {...attrs.left.eventDetails.bottom.date}>
              <CalendarIcon />
              <div {...attrs.left.eventDetails.bottom.value}>
                {isEmailAnalysis ? threadLifeTime : meetingDate}
              </div>
            </div>
            <div {...attrs.left.eventDetails.bottom.separator}>|</div>
            <div {...attrs.left.eventDetails.bottom.time}>
              <ClockIcon />
              <div {...attrs.left.eventDetails.bottom.value}>
                {isEmailAnalysis ? durationInWeeks : meetingDateRange}
              </div>
            </div>
            <div {...attrs.left.eventDetails.bottom.separator}>|</div>
            <div {...attrs.left.eventDetails.bottom.duration}>
              {RightIconComponent ? (
                <RightIconComponent {...attrs.rightIcon} />
              ) : null}
              <div {...attrs.left.eventDetails.bottom.value}>
                {meetingRecordingDurationString}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div {...attrs.right.wrapper}>
        <div {...attrs.right.content.wrapper}>
          <div {...attrs.right.content.peopleInfoWrapper}>
            <div {...attrs.right.content.host.wrapper}>
              <Avatar {...attrs.right.content.host.avatar} />
              {isEmailAnalysis ? (
                <>
                  <div {...attrs.right.content.host.text}>started by</div>
                  <div {...attrs.right.content.host.name}>
                    {threadStartedBy?.name}
                  </div>
                </>
              ) : (
                <>
                  <div {...attrs.right.content.host.name}>
                    {hostWithContactInfo?.contact?.metadata?.name}
                  </div>
                  <div {...attrs.right.content.host.text}>host</div>
                </>
              )}
            </div>
            <div {...attrs.right.content.praticipants.wrapper}>
              <div {...attrs.right.content.praticipants.avatars.wrapper}>
                {isEmailAnalysis
                  ? renderAvatars(emailParticipantsAvatarProps)
                  : renderAvatars(participantsAvatarProps)}
              </div>
            </div>
          </div>
          <div {...attrs.right.content.threeDotMenu.wrapper}>
            <div {...attrs.right.content.threeDotMenu.button.wrapper}>
              <ThreeDotIcon />
            </div>
            <ThreeDotAttentionIcon
              {...attrs.right.content.threeDotMenu.attentionIcon}
            />
          </div>
        </div>
        <Button {...attrs.navigation.nextButton}>
          <ChevronRightIcon />
        </Button>
      </div>
      <ManageParticipantsModal />
    </div>
  );
};

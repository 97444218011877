import { Contact } from './contact.types';
import { MeetingStatus } from './meetingDetails.types';

export enum RelationshipEventType {
  EMAIL_BRANCH = 'emailBranch',
  ONLINE_MEETING = 'onlineMeeting',
}

export interface RelationshipEventDetails {
  title?: string | null;
  emailsCount?: number | null;
  meetingStatus: MeetingStatus;
}

export interface RelationshipEventNavigation {
  touchPointId: string;
  type: RelationshipEventType;
}

export interface RelationshipEvent {
  eventDetails?: RelationshipEventDetails;
  touchPointId: string;
  type: RelationshipEventType;
  startDateTime: string;
  title: string;
  endDateTime: string | null;
  orgId: string;
  userOwnerId: string | null;
  accountId: string;
  lastActivity: string;
  contacts: Contact[] | [];
}

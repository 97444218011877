import React, { useCallback, useMemo, useState } from 'react';
import { CrossIcon } from './icons/CrossIcon';
import { EmailIcon } from './icons/EmailIcon';
import { MeetingIcon } from './icons/MeetingIcon';
import { getChangesTextDescription } from './utils/getChangesTextDescription';
import { Contact } from '../../../../../../../../types/contact.types';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import { useMixpanel } from '../../../../../../../../hooks';
import {
  Avatar,
  AvatarSize,
  Dropdown,
  EditIcon,
} from '../../../../../../../SoundWave';
import { DotsIcon } from '../../../../../../../Icons/DotsIcon';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../../../../../../../types/relationshipMember.types';
import { CheckedIcon } from '../../../../../../../SoundWave/components/FormField/icons/CheckedIcon';
import { changeMembersAssignment } from '../../../../../../../../store/relationshipDetails/relationshipDetails.slice';
import { MemberContactModal } from '../../../../../../../../pages/MyRelationships/components/relationshipDetails/components/MemberContactModal/MemberContactModal';

export interface MemberRowProps {
  contact: Contact;
  isSelected?: boolean;
  onSelect?: (isSelected: boolean, contact: Contact) => void;
  onDiscardChanges?: (contact: Contact) => void;
}

export const MemberRow: React.FC<MemberRowProps> = ({
  contact,
  isSelected = false,
  onSelect,
  onDiscardChanges,
}) => {
  const dispatch = useAppDispatch();

  const prospectAccount = useAppSelector(
    (state) => state.relationshipDetails.account
  );

  const userOrganization = useAppSelector((state) => state.auth.org);

  const { trackEvent } = useMixpanel();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isInfoModalOpened, setIsInfoModalOpened] = useState(false);

  const handleCloseDropdown = useCallback(() => {
    setIsDropdownOpened(false);
  }, []);

  const dropdownOptions = useMemo(() => {
    const { contactId, extra } = contact;
    const { isActive = false, contactSide, associationType } =
      extra?.currentAccountLink || {};

    const props = {
      activateIcon: {
        ...(isActive && {
          className: 'member-row__deactivate-icon',
        }),
      },
    };

    return [
      {
        label: 'Change Assignment',
        suboptions: [
          {
            label: prospectAccount?.name
              ? `${prospectAccount.name} Team Member`
              : 'Prospect Team Member',
            disabled:
              contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
              associationType === RelationshipMemberAssociationType.TEAM_MEMBER,
            ...(contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
              associationType ===
                RelationshipMemberAssociationType.TEAM_MEMBER && {
                icon: <CheckedIcon />,
              }),
            onClick: () => {
              trackEvent('assignMemberToProspectSide');
              setIsDropdownOpened(false);
              dispatch(
                changeMembersAssignment({
                  contactIds: [contactId],
                  contactSide: RelationshipMemberSide.PROSPECT_SIDE,
                  associationType:
                    RelationshipMemberAssociationType.TEAM_MEMBER,
                })
              );
            },
          },
          {
            label: prospectAccount?.name
              ? `${prospectAccount.name} 3rd Party`
              : 'Prospect 3rd Party',
            disabled:
              contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
              associationType === RelationshipMemberAssociationType.THIRD_PARTY,
            ...(contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
              associationType ===
                RelationshipMemberAssociationType.THIRD_PARTY && {
                icon: <CheckedIcon />,
              }),
            onClick: () => {
              trackEvent('assignMemberToProspectSide3rdParty');
              setIsDropdownOpened(false);
              dispatch(
                changeMembersAssignment({
                  contactIds: [contactId],
                  contactSide: RelationshipMemberSide.PROSPECT_SIDE,
                  associationType:
                    RelationshipMemberAssociationType.THIRD_PARTY,
                })
              );
            },
          },
          {
            label: userOrganization?.name
              ? `${userOrganization.name} Team Member`
              : 'User Team Member',
            disabled:
              contactSide === RelationshipMemberSide.USER_SIDE &&
              associationType === RelationshipMemberAssociationType.TEAM_MEMBER,
            ...(contactSide === RelationshipMemberSide.USER_SIDE &&
              associationType ===
                RelationshipMemberAssociationType.TEAM_MEMBER && {
                icon: <CheckedIcon />,
              }),
            onClick: () => {
              trackEvent('assignMemberToUserSide');
              setIsDropdownOpened(false);
              dispatch(
                changeMembersAssignment({
                  contactIds: [contactId],
                  contactSide: RelationshipMemberSide.USER_SIDE,
                  associationType:
                    RelationshipMemberAssociationType.TEAM_MEMBER,
                })
              );
            },
          },
          {
            label: userOrganization?.name
              ? `${userOrganization.name} 3rd Party`
              : 'User 3rd Party',
            disabled:
              contactSide === RelationshipMemberSide.USER_SIDE &&
              associationType === RelationshipMemberAssociationType.THIRD_PARTY,
            ...(contactSide === RelationshipMemberSide.USER_SIDE &&
              associationType ===
                RelationshipMemberAssociationType.THIRD_PARTY && {
                icon: <CheckedIcon />,
              }),
            onClick: () => {
              trackEvent('assignMemberToUserSide3rdParty');
              setIsDropdownOpened(false);
              dispatch(
                changeMembersAssignment({
                  contactIds: [contactId],
                  contactSide: RelationshipMemberSide.USER_SIDE,
                  associationType:
                    RelationshipMemberAssociationType.THIRD_PARTY,
                })
              );
            },
          },
          {
            label: 'Unassigned',
            disabled: contactSide === RelationshipMemberSide.UNASSIGNED,
            ...(contactSide === RelationshipMemberSide.UNASSIGNED && {
              icon: <CheckedIcon />,
            }),
            onClick: () => {
              trackEvent('unassignMember');
              setIsDropdownOpened(false);
              dispatch(
                changeMembersAssignment({
                  contactIds: [contactId],
                  contactSide: RelationshipMemberSide.UNASSIGNED,
                  associationType:
                    RelationshipMemberAssociationType.TEAM_MEMBER,
                })
              );
            },
          },
        ],
      },
      {
        label: 'Edit Member',
        icon: <EditIcon />,
        onClick: () => {
          setIsDropdownOpened(false);
          // if (prospectAccount) {
          //   dispatch(
          //     openAddMemberModal({
          //       accountId: prospectAccount.accountId,
          //       accountName: prospectAccount.name,
          //       isEditFlow: true,
          //       existingContact: contact,
          //       initialValues: {
          //         email: contact.email || '',
          //         firstName: contact.metadata?.firstName || '',
          //         lastName: contact.metadata?.lastName || '',
          //         title: contact.metadata?.title || '',
          //         phone: contact.metadata?.phoneNumbers?.[0]?.number || '',
          //         countryCode:
          //           contact.metadata?.phoneNumbers?.[0]?.countryCode || '',
          //         assignedTo:
          //           contact.extra?.currentAccountLink?.contactSide || '',
          //         contactType:
          //           contact.extra?.currentAccountLink?.associationType || '',
          //         avatar: contact.metadata.avatarSrc || '',
          //         note: contact.extra?.currentAccountLink?.note || '',
          //       },
          //     })
          //   );
          // }
        },
      },
    ];
  }, [contact, dispatch, prospectAccount, trackEvent, userOrganization]);

  const attrs = {
    container: {
      className: 'member-row',
    },
    checkbox: {
      type: 'button' as const,
      className: 'member-row__checkbox',
      onClick: () => {
        trackEvent('selectMember');
        onSelect?.(!isSelected, contact);
      },
    },
    avatar: {
      src: contact.metadata?.avatarSrc || '',
      name: contact.metadata?.name || contact.email || '',
      side: contact.extra?.currentAccountLink?.contactSide,
      size: AvatarSize.XS,
      className: 'member-row__avatar',
    },
    text: {
      className: 'member-row__text',
    },
    textGray300: {
      className: 'member-row__text member-row__text--gray-300',
    },
    textGray200: {
      className: 'member-row__text member-row__text--gray-200',
    },
    eventsCount: {
      className: 'member-row__events-count',
    },
    eventsCountText: {
      className:
        'member-row__text member-row__text--gray-300 member-row__text--bold',
    },
    separator: {
      className: 'member-row__separator',
    },
    leftSection: {
      className: 'member-row__left-section',
    },
    rigthSection: {
      className: 'member-row__right-section',
    },
    changesButton: {
      type: 'button' as const,
      className: 'member-row__changes-button',
      onClick: () => {
        // trackEvent('discardMemberChanges');
        // onDiscardChanges?.(contact);
        // dispatch(discardMemberChanges(contact.contactId));
      },
    },
    dropdownButton: {
      type: 'button' as const,
      className: `member-row__dropdown-button${
        isDropdownOpened ? ' member-row__dropdown-button--active' : ''
      }`,
      onClick: () => {
        setIsDropdownOpened(!isDropdownOpened);
      },
    },
    dropdown: {
      isOpened: isDropdownOpened,
      closeOnScroll: true,
      onClose: handleCloseDropdown,
      options: dropdownOptions,
    },
    memberContactModal: {
      contact,
      isOpened: isInfoModalOpened,
      onClose: () => {
        setIsInfoModalOpened(false);
      },
    },
  };

  return (
    <div {...attrs.container}>
      <Avatar {...attrs.avatar} />
      <div {...attrs.leftSection}>
        <span {...attrs.text}>{contact.metadata?.name || contact.email}</span>
        {contact.metadata?.title ? (
          <>
            <span {...attrs.separator} />
            <span {...attrs.textGray300}>{contact.metadata?.title}</span>
          </>
        ) : null}
      </div>
      <div {...attrs.rigthSection}>
        <>
          {contact.email ? (
            <span {...attrs.text}>{contact.email}</span>
          ) : (
            <span {...attrs.textGray200}>no email detected</span>
          )}
          <Dropdown {...attrs.dropdown}>
            <button {...attrs.dropdownButton}>
              <DotsIcon />
            </button>
          </Dropdown>
        </>
      </div>
      <MemberContactModal {...attrs.memberContactModal} />
    </div>
  );
};

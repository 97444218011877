import { Duration } from 'luxon';

export const getTotalDurationString = (
  durationInWeeks: number | null
): string => {
  if (durationInWeeks === null) {
    return 'N/A';
  }

  const { weeks } = Duration.fromObject({ weeks: durationInWeeks });
  const weeksAmount = +weeks.toFixed(0);

  if (weeksAmount < 1) {
    return '< 1 week';
  }

  return `${weeksAmount} week${weeksAmount === 1 ? '' : 's'}`;
};

import React from 'react';

export const ChevronLeftIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9964 8.87797C4.55654 8.37527 4.55654 7.62466 4.9964 7.12196L9.27894 2.22765C9.5214 1.95056 9.94257 1.92249 10.2197 2.16494C10.4968 2.4074 10.5248 2.82857 10.2824 3.10566L5.99984 7.99996L10.2824 12.8943C10.5248 13.1714 10.4968 13.5926 10.2197 13.835C9.94257 14.0775 9.52139 14.0494 9.27894 13.7723L4.9964 8.87797Z"
      fill="#070B12"
    />
  </svg>
);

import React from 'react';

export const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M14.1667 2.50033C14.1667 2.04009 13.7936 1.66699 13.3333 1.66699C12.8731 1.66699 12.5 2.04009 12.5 2.50033V4.16699C12.5 4.62723 12.8731 5.00033 13.3333 5.00033C13.7936 5.00033 14.1667 4.62723 14.1667 4.16699V2.50033Z"
      fill="#95A1B6"
    />
    <path
      d="M7.5 2.50033C7.5 2.04009 7.1269 1.66699 6.66667 1.66699C6.20643 1.66699 5.83333 2.04009 5.83333 2.50033V4.16699C5.83333 4.62723 6.20643 5.00033 6.66667 5.00033C7.1269 5.00033 7.5 4.62723 7.5 4.16699V2.50033Z"
      fill="#95A1B6"
    />
    <path
      d="M11.6667 4.16699H8.33333C8.33333 5.08747 7.58714 5.83366 6.66667 5.83366C5.74619 5.83366 5 5.08747 5 4.16699H4.16667C3.24619 4.16699 2.5 4.91319 2.5 5.83366V7.81977H17.5V5.83366C17.5 4.91319 16.7538 4.16699 15.8333 4.16699H15C15 5.08747 14.2538 5.83366 13.3333 5.83366C12.4129 5.83366 11.6667 5.08747 11.6667 4.16699Z"
      fill="#95A1B6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 9.48644H17.5V16.667C17.5 17.5875 16.7538 18.3337 15.8333 18.3337H4.16667C3.24619 18.3337 2.5 17.5875 2.5 16.667V9.48644ZM7.5 11.667C7.5 12.1272 7.1269 12.5003 6.66667 12.5003C6.20643 12.5003 5.83333 12.1272 5.83333 11.667C5.83333 11.2068 6.20643 10.8337 6.66667 10.8337C7.1269 10.8337 7.5 11.2068 7.5 11.667ZM6.66667 15.8337C7.1269 15.8337 7.5 15.4606 7.5 15.0003C7.5 14.5401 7.1269 14.167 6.66667 14.167C6.20643 14.167 5.83333 14.5401 5.83333 15.0003C5.83333 15.4606 6.20643 15.8337 6.66667 15.8337ZM10.8333 11.667C10.8333 12.1272 10.4602 12.5003 10 12.5003C9.53976 12.5003 9.16667 12.1272 9.16667 11.667C9.16667 11.2068 9.53976 10.8337 10 10.8337C10.4602 10.8337 10.8333 11.2068 10.8333 11.667ZM10 15.8337C10.4602 15.8337 10.8333 15.4606 10.8333 15.0003C10.8333 14.5401 10.4602 14.167 10 14.167C9.53976 14.167 9.16667 14.5401 9.16667 15.0003C9.16667 15.4606 9.53976 15.8337 10 15.8337ZM14.1667 11.667C14.1667 12.1272 13.7936 12.5003 13.3333 12.5003C12.8731 12.5003 12.5 12.1272 12.5 11.667C12.5 11.2068 12.8731 10.8337 13.3333 10.8337C13.7936 10.8337 14.1667 11.2068 14.1667 11.667Z"
      fill="#95A1B6"
    />
  </svg>
);

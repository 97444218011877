import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { toggleManageParticipantsModal } from '../../../../../../store/meetingDetails/meetingDetails.slice';
import { Modal } from '../../../../../SoundWave';
import { MemberRow } from './components/MemberRow/MemberRow';

export interface ManageParticipantsModalProps {
  props?: any;
}

const ManageParticipantsModal: React.FC<ManageParticipantsModalProps> = ({
  props,
}) => {
  const { data } = useAppSelector((state) => state.meetingDetails);
  const { isModalOpened } = useAppSelector(
    (state) => state.meetingDetails.manageParticipants
  );
  const dispatch = useAppDispatch();

  const attrs = {
    Modal: {
      isOpened: isModalOpened,
      closeOnClickOutside: true,
      width: 460,
      onClose: () => {
        dispatch(toggleManageParticipantsModal(false));
      },
      onAnimationEnd: () => {
        // reset
      },
      headerProps: {
        title: 'Participants',
      },
      //   footerProps: {
      //     formId,
      //     isLoading,
      //     cancelButtonText: 'Discard',
      //     confirmButtonText: accountId ? 'Apply' : 'Create Account',
      //     isConfirmButtonDisabled: !isValid || !dirty,
      //   },
    },
  };

  const mapperMemberRows = data?.contacts
    ? data.contacts.map((el, i) => <MemberRow contact={el} key={i} />)
    : null;

  return (
    <Modal {...attrs.Modal}>
      <div>{mapperMemberRows}</div>
    </Modal>
  );
};

export default ManageParticipantsModal;

import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { PATHS } from '../../constants/paths';
import {
  EventHeader,
  EventHeaderProps,
} from './components/EventHeader/EventHeader';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import { EventFrameHeader } from './components/EventFrameHeader/EventFrameHeader';

export interface EventFrameProps {
  headerProps: EventHeaderProps;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  isEmailAnalysis?: boolean;
}

export const EventFrame: React.FC<EventFrameProps> = ({
  headerProps,
  leftContent,
  rightContent,
  isEmailAnalysis = false,
}) => {
  const isTestPage = useRouteMatch(PATHS.MEETING_DETAILS_TEST);

  const attrs = {
    container: {
      className: `event-frame${isTestPage ? ' event-frame--test' : ''}`,
    },
    eventHeader: headerProps,
    contentWrapper: {
      className: 'event-frame__content-wrapper',
    },
    left: {
      className: 'event-frame__left',
    },
    right: {
      className: 'event-frame__right',
    },
    rightScrollbar: {
      className: 'event-frame__right-scrollbar',
    },
  };

  return (
    <div {...attrs.container}>
      {!isEmailAnalysis && !!isTestPage ? (
        <EventFrameHeader {...attrs.eventHeader} />
      ) : (
        <EventHeader {...attrs.eventHeader} />
      )}
      <div {...attrs.contentWrapper}>
        <div {...attrs.left}>{leftContent}</div>
        {isTestPage ? (
          <div {...attrs.right}>{rightContent}</div>
        ) : (
          <Scrollbar {...attrs.rightScrollbar}>
            <div {...attrs.right}>{rightContent}</div>
          </Scrollbar>
        )}
      </div>
    </div>
  );
};

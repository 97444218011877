import React from 'react';

export const ClockIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699C5.39795 1.66699 1.66699 5.39795 1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337ZM13.3337 10.8337C13.7939 10.8337 14.167 10.4606 14.167 10.0003C14.167 9.54009 13.7939 9.16699 13.3337 9.16699H10.8337V6.66699C10.8337 6.20676 10.4606 5.83366 10.0003 5.83366C9.54009 5.83366 9.16699 6.20675 9.16699 6.66699V10.0003C9.16699 10.4606 9.54009 10.8337 10.0003 10.8337H13.3337Z"
      fill="#95A1B6"
    />
  </svg>
);

import { Contact } from '../../../../../../types/contact.types';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../../../../../types/relationshipMember.types';

export const getPhoneNumberString = (contact?: Contact): string => {
  const { phoneNumbers = [] } = contact?.metadata || {};
  const phoneNumber = phoneNumbers?.[0];

  if (!phoneNumber) {
    return '-';
  }

  const { number, countryCode } = phoneNumber;
  return `${countryCode || ''}${number}`;
};

export const getAssignedToString = (
  contact?: Contact,
  prospectAccountName?: string,
  userOrganizationName?: string
): string => {
  const { contactSide } = contact?.extra?.currentAccountLink || {};

  if (!contactSide) {
    return '-';
  }

  switch (contactSide) {
    case RelationshipMemberSide.USER_SIDE: {
      return userOrganizationName
        ? `${userOrganizationName} Team`
        : 'User Team';
    }
    case RelationshipMemberSide.PROSPECT_SIDE: {
      return prospectAccountName
        ? `${prospectAccountName} Team`
        : 'Prospect Team';
    }
    case RelationshipMemberSide.UNASSIGNED: {
      return 'Unassigned';
    }
    default: {
      return '-';
    }
  }
};

export const getContactTypeString = (contact?: Contact): string => {
  const { associationType } = contact?.extra?.currentAccountLink || {};

  if (!associationType) {
    return '-';
  }

  switch (associationType) {
    case RelationshipMemberAssociationType.TEAM_MEMBER: {
      return 'Team Participant';
    }
    case RelationshipMemberAssociationType.THIRD_PARTY: {
      return 'Associated 3rd Party';
    }
    default: {
      return '-';
    }
  }
};
